<template>
  <ul class="member-list">
    <li class="member-list-item" v-for="(item, index) in memberList" :key="index">
      <div class="member-cover">
        <img :src="item.photo_url" />
      </div>
      <div class="member-content">
        <div class="fsbc">
          <h3 class="member-name">{{ item.realname }}</h3>
          <span class="fs14">{{ item.stage_text }} - {{ item.subject_text }}</span>
        </div>
        <p class="member-unit">{{ item.organization_name }}</p>
        <!-- <span class="member-chat">私信TA</span> -->
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    //活动信息数据
    activityInfoData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      memberForm: {
        is_personid: this.$route.query.is_from ? this.$route.query.is_from : "",
        activity_id: 0,
        limit: 100000,
        page: 1,
      },
      memberList: [],
    };
  },
  created() {
    this.memberForm.activity_id = this.$route.query.activityId;
    this.getMember();
  },
  methods: {
    //获取活动成员
    getMember() {
      this.$axios
        .get(`index/UserActivity/UserActivityLists`, { params: this.memberForm })
        .then((res) => {
          let data = res.data;
          if (data.code == 900) {
            this.memberList = data.data.data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.member-list {
  padding: 26px;
  display: flex;
  flex-wrap: wrap;

  .member-list-item {
    width: 322px;
    height: 78px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    padding: 10px 14px;
    margin: 0 20px 20px 0;

    &:nth-child(4n + 4) {
      margin-right: 0;
    }

    &:hover {
      .member-chat {
        display: flex !important;
      }
    }

    .member-cover {
      width: 58px;
      height: 58px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 16px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .member-content {
      flex: 1;
      overflow: hidden;
      position: relative;

      .member-name {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        padding: 6px 0;
      }

      .member-unit {
        font-size: 14px;
        color: #999999;
      }

      .member-chat {
        width: 72px;
        height: 32px;
        background: #3489fe;
        border-radius: 4px;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 12px;
        display: none;
      }
    }
  }
}
</style>
